import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup } from "availity-reactstrap-validation";
import {
    currentDateTime,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select"
import countries from "../../common/data/countries";
import AsyncSelect from 'react-select/async';

export default class AttachCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id != '' ? this.props.id : '',
            record: {},
            clients: [],
            clients_id: 0,
            permissions: [],
            permission: [],
            emails: [],
            phoneNumbers: [],
            email_reminder: false,
            sms_reminder: false,
            pipelines: []

        };
    }

    componentDidMount() {
         this.getData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.id != this.props.id) {
            this.setState({
                id: this.props.id != '' ? this.props.id : '',
                record: {},
            }, () => this.getData())
        }
    }

  
    getData = () => {
        fetch(`${ApiUrl}` + "campaigns", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        records: response.data.records,
                    });
                } 
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    handleSubmit = async (event, values) => {
        values.id = this.state.id;
        fetch(`${ApiUrl}` + "assign/campaign/access", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data, 'Editing');
                if (data.status === true) {
                    if (this.state.id == null) {
                        this.form && this.form.reset();
                    }this.props.cancelSlider();

                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    render() {
        return (
            <React.Fragment>
                <Row className="m-1">
                    <Col className="col-12">
                        <AvForm
                            onValidSubmit={this.handleSubmit}
                            ref={(c) => (this.form = c)}
                            className="needs-validation"
                        >
                            <ModalBody className="row">
                                <div className="col-md-12 mb-3">
                                    <AvField
                                        className="select form-control"
                                        type="select"
                                        name="campaign_id"
                                        label="Campaign"
                                        required
                                      >
                                       <option value=""> Select Campaign </option>
                                        {this.state.records &&
                                          this.state.records.map((row, i) => (
                                            <option key={i} value={row.campaign_id}>
                                              {row.title}
                                            </option>
                                          ))}
                                      </AvField>
                                </div>
                                  <div className="col-md-12 mb-3">
                                    <AvField
                                        className="select form-control"
                                        type="text"
                                        name="phone_name"
                                        label="Name this number (optional)"
                                    />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button
                                    className="btn btn-primary waves-effect waves-light"
                                    type="submit"
                                >
                                    Save Changes
                                </button>
                            </ModalFooter>
                        </AvForm>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
