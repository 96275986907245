import React, { Component, Fragment } from "react";
import Loader from "react-loader";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Table,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import {
  ApiUrl,
  ProductName,
  ToastAutoClose,
  AttachementsUrl,
} from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ProfileSettings from "../Authentication/profile-sidebar";
import $ from "jquery";
import swal from 'sweetalert';
export default class purchase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      records: [],
      selected_phone_arr: [],
      total_pages: 0,
      del_records: [],
      total_pages_del: 0,
      modal: false,
      updatemodal: false,
      record: {},
      filter: {},
      page_name: "Caller ID",
      customDate: true,
      activeTab: "1",
      loaded: true,
      phone_records: {},
      seach_by_type: "areaCode",
      country_code: "US",
      phone_type: "local",
      seach_by_value: "",
      unlockFlow: {
        groups: []
      },
      loader: false,
    };
  }

  componentDidMount() {
    this.getTwilioNumber("", "");
  }



  getTwilioNumber = (queryString = "", data) => {
    this.setState({ loaded: false });
    fetch(`${ApiUrl}` + "get/user/twilio/available_numbers" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ data }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            phone_records: response.data.records,
            campaign_records: response.data.campaign_records,
          });
          this.setState({ loaded: true });
        } else {
          this.setState({ loaded: true });


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  resetPhoneField = async (data) => {
    this.setState({
      seach_by_type: "areaCode",
      country_code: "US",
      phone_type: "local",
      area_code: "",
    });
    this.getTwilioNumber("", "");
  }


  handleChangeType = async (value) => {
    $('input[name=selected_numbers]').prop('checked', false);
    let queryString = encodeURIComponent('phone_type') + "=" + encodeURIComponent(value);
    this.getTwilioNumber("?" + queryString, "");
  };
  handleFilterSubmit = async (event, data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
      })
      .join("&");
    this.getTwilioNumber("?" + queryString, "");
  };
  handleSelectPhone = (e) => {
    let isChecked = e.target.checked;
    let phone = e.target.value;
    var selected_phone_arr = [];
    if (isChecked) {
      selected_phone_arr.push(phone);
      this.setState({ selected_phone_arr: selected_phone_arr });
    } else {
       this.setState({ selected_phone_arr: selected_phone_arr });
    }
   
  };
handleSubmit = async (event, values) => {
    this.setState({
      loader: true,
    })
    values.phone_type = this.state.phone_type;
    values.selected_phone_arr = this.state.selected_phone_arr;
    fetch(`${ApiUrl}` + "purchase/phone/numbers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          swal({
            text: "Phone Number Purchased successfully",
            icon: "success",
          }).then((confirm) => {
              if (confirm) {
                this.props.history.push('/caller/numbers/list')
              }
          })
        } else {
          this.setState({
            loader: false,
          })
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
        })
        console.error("Error:", error);
      });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col lg="12">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                 
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </Row>

            <ToastContainer />
           
            <Card>
              <Loader loaded={this.state.loaded}>
                <AvForm
                  onValidSubmit={this.handleFilterSubmit}
                  ref={(c) => (this.form = c)}
                  className="needs-validation"
                >
                  <ModalBody className="row">
                    <div className="col-md-2 mb-3 d-none">
                      <AvField
                        className="select form-control"
                        type="select"
                        name="phone_type"
                        label="Phone Type"
                        onChange={(e) => {
                          this.setState({ "phone_type": e.target.value });
                          this.handleChangeType(e.target.value);

                        }}
                        value={this.state.phone_type}
                      >
                        <option value="local">Local Numbers</option>
                        <option value="toll_free">Toll Free Numbers</option>
                      </AvField>
                    </div>
                    <div className="col-md-2 mb-3">
                      <AvField
                        className="select form-control"
                        type="select"
                        name="country_code"
                        label="Country"
                        onChange={(e) => {
                          this.setState({ "country_code": e.target.value });
                        }}
                        value={this.state.country_code}
                      >
                        <option value="US">USA</option>
                        <option value="CA">Canada</option>
                      </AvField>
                    </div>
                    <div className="col-md-2 mb-3">
                      <AvField
                        className="select form-control"
                        type="select"
                        name="seach_by_type"
                        label="Search By"
                        value={this.state.seach_by_type}
                        onChange={(e) => { this.setState({ "seach_by_type": e.target.value }) }}
                      >
                        <option value="areaCode">Area Code</option>
                        <option value="contains">Phone</option>
                      </AvField>
                    </div>

                    <div className="col-md-3 mb-3">
                      <AvField
                        name="area_code"
                        value={this.state.area_code}
                        placeholder="Enter search value"
                        label="-"
                        className="form-control"
                        onKeyUp={(e) => { this.setState({ "area_code": e.target.value }) }}
                      />
                    </div>
                    <div className="col-md-3 mt-4 button-items">
                      <div className="form-group">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          type="submit"
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-info waves-effect waves-light"
                          type="button"
                          onClick={this.resetPhoneField}
                        >
                          Reset
                        </button>
                      </div>
                    </div>

                  </ModalBody>
                </AvForm>
              </Loader>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>
                 Select a phone number to purchase.
                </CardTitle>
                <Row>
                  <AvForm
                    onValidSubmit={this.handleSubmit}
                    ref={(d) => (this.form = d)}
                    className="needs-validation"
                  >
                    <div className="table-responsive mb-0 col-md-12" data-pattern="priority-columns">
                      <table className="table table-striped table-bordered responsiveTable">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Number</th>
                            <th>Price</th>
                            <th>Region</th>
                            <th>Postal Code</th>
                            <th>Locality</th>
                            <th>Address Requirements</th>
                            <th>Voice Enabled</th>
                            <th>SMS Enabled</th>
                            <th>MMS Enabled</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.phone_records.length > 0 ? (
                            this.state.phone_records.map((row, i) => (
                              <tr key={i + '--' + row.id}>
                                <td align="center">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="selected_numbers"
                                    value={row.phone_number}
                                    onChange={(e) => this.handleSelectPhone(e)}
                                  />
                                </td>
                                <td align="center">{row.phone_number}</td>
                                <td align="center">$5/monthly</td>
                                <td align="center">{row.region}</td>
                                <td align="center">{row.postal_code === '' ? '--' : row.postal_code}</td>
                                <td align="center">{row.locality}</td>
                                <td align="center">{row.address_requirements}</td>
                                <td align="center">{row.capabilities.voice ? 'True' : 'False'}</td>
                                <td align="center">{row.capabilities.SMS ? 'True' : 'False'}</td>
                                <td align="center">{row.capabilities.MMS ? 'True' : 'False'}</td>
                              </tr>
                            ))
                          ) : (
                            <tr key="-1">
                              <td colSpan={9}>
                                <div className="col-md-12 text-center">No record found</div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {this.state.phone_records.length > 0 && this.state.selected_phone_arr.length > 0 && (
                      <div className="col-md-12">
                        <div className="col-md-3 mb-3">
                          <AvField
                            className="select form-control"
                            type="select"
                            name="campaign_id"
                            label="Assign to Campaign (optional)"
                          >
                           <option value=""> Select Campaign </option>
                            {this.state.campaign_records &&
                              this.state.campaign_records.map((row, i) => (
                                <option key={i} value={row.campaign_id}>
                                  {row.title}
                                </option>
                              ))}
                          </AvField>
                        </div>

                         <div className="col-md-3 mb-3">
                          <AvField
                            className="select form-control"
                            type="text"
                            name="phone_name"
                            label="Name this number (optional)"
                          />
                           
                        </div>
                        
                        <div className="col-md-12">
                          <div className="col-md-3 mt-4 button-items">
                            <div className="form-group">
                              <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                disabled={this.state.loader}
                              >
                                Buy Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </AvForm>
                </Row>
              </CardBody>
            </Card>

          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
