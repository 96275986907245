import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  CardTitle,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  convertPhoneFormat,
  dcrypt,
  hasRole,
  isAllowed,
  uInfo,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import $ from "jquery";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import Phone from "../../InitiateCall/Phone";
import { createBrowserHistory } from "history";
import favicon from "../../../assets/images/favicon.png";
import SearchFile from "../../../assets/images/search-file.png";


export default class UsersDatatableTables extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        text: "First Name",
        key: "first_name",
      },
      {
        text: "Last Name",
        key: "last_name",
      },
      {
        text: "Phone Number",
        key: "phone",
      },
      {
        text: "Email",
        key: "email",
      },
      {
        text: "Address",
        key: "address",
      },
      {
        text: "Date Created",
        key: "created_at",
      },
      {
        text: "Last Call",
        key: "last_call",
      },
      
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              <button
                type="button"
                color="info"
                className="btn btn-primary btn-sm mb-1 mr-5"
               onClick={() => this.props.history.push("/contact/edit/" + bcrypt(record.client_id)+"/"+ bcrypt(record.coach_id))}
              >
                 <i className="fas fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm mb-1"
                onClick={this.deleteRecord.bind(this, record, index)}
              >
                <i className="fas fa-trash"></i>
              </button> 
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 25,
      length_menu: [25, 100, 150, 250],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      records: [],
      coachPartners: [],
      total_pages: 0,
      modal: false,
      record: {},
      info: {
        groups_data: [],
        records: {},
        tag_data: [],
      },
      filter: {},
      page_name: "Lead",
      customDate: true,
      ClientSubcribeModal: false,
      groups: [],
      currentClient: {
        id: "",
        email_status: 1,
        call_status: 1,
        groups: [],
        tags: [],
      },
      customFieldData: { client_ids: [] },
      page1Class: '',
      phoneNumbers: [],
      quickDialer: [],
      slide: '',
      tokens: [],
      phoneNumbers: [],
      smsTemplates: [],
      dynamicModal: false,
      emailModal: false,
      dynamicModalName: 'note',
      selectedClient: {},
      token: '',
      html: '',
      callToken: null,
      phone_number: '',
      paramsters: {
        from_number: uInfo().outbound_phone,
      },
      notes: [],
      searchFilter: false,
      actionTitle: 'Action'

    };
    this.identity = "proglobal";
  }


  getLeadDetails = (idx, coach_id) => {
    fetch(`${ApiUrl}` + "lead/details/" + idx + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            info: response.data,
            page5Class: 'slide-in'
          })
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  createAssessment = async (client_id, name) => {
    fetch(`${ApiUrl}` + "add/assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ name: name, client_id: client_id, assessment_currency: 'USD', module: 6 }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          this.props.history.push(`/assessment/${bcrypt(data.data.assessment_id)}/summary`)
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  generateClientLogin = (client_id, coach_id) => {
    fetch(`${ApiUrl}` + "generate/client/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ client_id: client_id, coach_id: coach_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          document.getElementById('client_' + client_id).style.display = "none";
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleChange(e) {
    const { name, value, checked } = e.target;
    if (!checked) {
      this.setState((prevState) => ({
        customFieldData: {
          ...prevState.customFieldData,
          ['client_ids']: this.state.customFieldData['client_ids'].filter(
            (color) => color !== value
          ),
        },
      }));

      $(e).prop("checked", false);
    } else {
      this.setState((prevState) => ({
        customFieldData: {
          ...prevState.customFieldData,
          ['client_ids']: [...prevState.customFieldData['client_ids'], value],
        },
      }));
    }

    console.log(this.state.customFieldData, 'customFieldData')

  }
  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/client", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.client_id, coach_id: record.coach_id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };

  componentDidMount() {
    const history = createBrowserHistory();
    if (history.location.state && (history.location.state.msg || history.location.state.back)) {
      this.openForm(); this.setState({
        slide: 'slide-in'
      });

      let state = { ...history.location.state };
      delete state.msg;
      delete state.back;
      history.replace({ ...history.location, state });
    }


    this.getData();
    //this.getfilterData();
    //this.getFormData();
    //this.handleOutCall();
  }

  getFormData = () => {
    fetch(`${ApiUrl}` + "get/quick/dialer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {

        if (response.status === true) {
          this.setState({
            quickDialer: response.data.records,
            phoneNumbers: response.data.phoneNumbers,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };



  getData = (queryString = "", data) => {
    
    let query = "";
    if (queryString == "") {
      query = "?lead_type=contacts&";
    } else {
      query = queryString + "&lead_type=contacts";
    }
    fetch(`${ApiUrl}` + "get/contacts" + query, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log(response, "TESTINGGG");
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            customFieldData: { client_ids: [] },
            coachPartners: response.data.coach_partners
          });
          $('input[type="checkbox"]:checked').prop('checked', false)

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true, group_id: 0 });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  openClientSubcribeModal = (client, index) => {
    this.setState(
      {
        currentClient: client,
      },
      () =>
        this.setState({
          ClientSubcribeModal: true,
        })
    );
  };

  ClientSubcribeSubmit = async (event, values) => {
    if (this.state.customFieldData['client_ids'].length > 0) {
      values.id = this.state.customFieldData['client_ids'];
    } else {
      values.id = this.state.currentClient.client_id;
    }
    // values.coach_id = this.state.currentClient.coach_id;
    fetch(`${ApiUrl}` + "client/subscribe/status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            ClientSubcribeModal: false,
          });
          this.getData();
          this.setState({
            currentClient: {
              id: "",
              email_status: "",
              call_status: "",
            },
            customFieldData: { client_ids: [] },
          });
          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getfilterData = () => {
    fetch(`${ApiUrl}` + "get/group/tag", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ coach_id: uInfo().user_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            groups: response.data.groups,
            tags: response.data.tags,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmitQuickDialer = async (event, values) => {
    values.clients_ids = this.state.customFieldData.client_ids.toString();
    fetch(`${ApiUrl}` + "add/quick/dialer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.status === true) {
          setTimeout(
            () => this.props.history.push(`/campaign/outbounddialer/start?runId=${bcrypt(data.data.campaign_id)}&dialer_type=quick-dialer`),
            1000
          );

        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  openForm() {
    document.getElementById("myForm").style.display = "block";
  }

  closeForm() {
    document.getElementById("myForm").style.display = "none";
  }


  groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  getSmsTemplate = () => {
    fetch(`${ApiUrl}` + "get/sms/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          var templates = this.groupArrayOfObjects(response.data.templates, "type");
          var templates_group = [
            { label: "SMS", options: templates.SMS },
            { label: "MMS", options: templates.MMS },
          ];

          var tokens_group = [
            {
              label: "Default Field",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Custom Field",
              options: response.data.tokens.custom_fields,
            },
          ];

          this.setState({
            smsTemplates: templates_group,
            tokens: tokens_group,
            phoneNumbers: response.data.phoneNumbers
          })
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  closeDynamicModal = () => {
    this.setState({
      dynamicModal: false,
      emailModal: false,
      dynamicModalName: '',
      smsBody: '',
      token: "",
      subject: "",
      html: "",
      notes: [],
      selectedClient: {}

    });
  }

  handleSubmit = async (event, values) => {
    values.client_id = this.state.selectedClient.client_id;
    values.type = this.state.dynamicModalName.toLowerCase();
    fetch(`${ApiUrl}` + "lead/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          this.closeDynamicModal();
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleEmailSubmit = async (event, values) => {
    values.client_id = this.state.selectedClient.client_id;
    values.type = this.state.dynamicModalName.toLowerCase();
    if (this.state.html != "") {
      let body = document.getElementById("custom-template").innerHTML;
      values.body = body;
    }

    fetch(`${ApiUrl}` + "company/email/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          this.closeDynamicModal();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getEmailTemplate = () => {
    fetch(`${ApiUrl}` + "get/email/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          var tokens_group = [
            {
              label: "Default Field",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Custom Field",
              options: response.data.tokens.custom_fields,
            },
          ];

          this.setState({
            tokens: tokens_group,
            smsTemplates: response.data.templates
          })
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getEmailTemplateHtml = (id) => {
    fetch(`${ApiUrl}` + "get/html/" + id + "/" + uInfo().user_id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        this.setState({ html: response.theme })
        // localStorage.setItem("theme", response.theme);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleOutCall = () => {
    fetch(`${ApiUrl}` + "voice/token", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ identity: encodeURIComponent(this.identity) }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {

          this.setState(prevState => ({
            paramsters: {                   // object that we want to update
              ...prevState.paramsters,    // keep all other key-value pairs
              token: response.data.token,
              from_number: uInfo().outbound_phone,
            }
          }));

          this.setState({
            callToken: response.data.token
          })
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getNotes = (id) => {
    fetch(`${ApiUrl}` + "get/activity/" + id + "/5", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            notes: response.data.records
          })
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  getCallSid = (call_sid) => {

  }

  resetDialAttempt = (campaign_id) => {
    fetch(`${ApiUrl}` + "campaign/reset/attempt", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: campaign_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          setTimeout(() => {
            this.props.history.push({
              pathname: '/lead/list',
            });
          }, 2000);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  agentAction = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }
    submitData.append('ids', this.state.customFieldData.client_ids);
    submitData.append('action_type', event.nativeEvent.submitter.name)
    fetch(`${ApiUrl}` + "client/agent/action", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          swal({
            title: "Success",
            text: data.message,
            icon: "success",
            dangerMode: false,
          })
          this.getData();
        } else {
          swal({
            title: "Error",
            text: data.message,
            icon: "warning",
            dangerMode: true,
          })
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        // this.setState({ loader: true });
      })
      //Then with the error genereted...
      .catch((error) => {
        this.setState({ loader: true });

        console.error("Error:", error);
      });
  }
  render() {

    const queryParams = this.props.location.state ? this.props.location.state : '';
    const sessionMSg = queryParams.msg ? queryParams.msg : '';
    const sessionCampaignId = queryParams.campaign_id ? queryParams.campaign_id : 0;



    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
          </title>
        </Helmet>
        {uInfo().type === 'Coach' && (
          <Row className="mb-2">
            <Col sm={8}>
            </Col>
            <Col sm={4}>
              <div className="input-group justify-content-end">

                  <Link
                    type="button"
                    color="info"
                    className="btn btn-info mr-5"
                    to={"/contact/add"}
                  >
                    <i className="mdi mdi-plus"> </i>
                    Create
                  </Link>
                  <Link
                    type="button"
                    color="info"
                    className="btn btn-info mr-5"
                    to={"/custom/field/list"}
                  >
                    <i className="mdi mdi-cog"> </i>
                    Custom Fields
                  </Link>
              </div>
            </Col>
          </Row>
        )}
        {
          sessionMSg && sessionMSg != '' && (
            <>
              <Alert color="info">{dcrypt(sessionMSg)} <button onClick={() => this.resetDialAttempt(sessionCampaignId)} className="btn btn-danger">Click to reset</button></Alert>
            </>

          )
        }



        <div className={`form-popup-client page3 ${this.state.slide}`} id="myForm">
          <div className="form-container">
            <div className="modal-head top-heading">
              <h2> Quick Dialer </h2>
              <button
                onClick={() => {
                  this.closeForm();
                  this.openForm(); this.setState({
                    slide: ''
                  })
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <Card>
              <CardBody>
                <table className="table table-bordered table-stripped">
                  <thead>
                    <tr>
                      <th>#id</th>
                      <th>Title</th>
                      <th>Total Client</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.quickDialer.map((row, i) => {
                        return <tr key={i}>
                          <td>{row.campaign_id}</td>
                          <td>{row.title}</td>
                          <td>{row.total_clients}</td>
                          <td><Link title="Start dialer" to={`/campaign/outbounddialer/start?runId=${bcrypt(row.campaign_id)}&dialer_type=quick-dialer&from=lead`}><i className="fas fa-grip-horizontal"></i></Link></td>

                        </tr>
                      })
                    }

                  </tbody>
                </table>
              </CardBody>
            </Card>
          </div>
        </div>

        {
          this.state.page5Class == "slide-in" && (
            <div className={`page3 ${this.state.page5Class} custom-scroll`}>
              <div>
                <div className="modal-head top-heading">
                  <h2> Lead Info </h2>
                  <button
                    onClick={() => {
                      this.setState({
                        page5Class: 'slide-out2'
                      })
                    }}
                    className="btn btn-link text-white"
                  >
                    <i className="fas fa-times"> </i>
                  </button>
                </div>
                <table className="table table-bordered mt-3">
                  <tbody>
                    <tr>
                      <td> First Name </td> <td> {this.state.info.records.first_name} </td>
                      <td> Last Name </td> <td> {this.state.info.records.last_name} </td>
                    </tr>
                    <tr>
                      <td> Email </td> <td> {this.state.info.records.email} </td>
                      <td>  </td>
                      <td>  </td>
                    </tr>

                    <tr>
                      <td> Mobile </td> <td> {convertPhoneFormat(this.state.info.records.country_code + this.state.info.records.mobile)} </td>
                      <td> Phone </td> <td> {convertPhoneFormat(this.state.info.records.phone_extension + this.state.info.records.phone)} </td>
                    </tr>

                    <tr>
                      <td> Client Source </td> <td> {this.state.info.records.source} </td>
                      <td> Client Id </td> <td> {this.state.info.records.client_id} </td>
                    </tr>
                    <tr>
                      <td colSpan={4}> ADDRESS INFORMATION </td>
                    </tr>
                    <tr>
                      <td> Address </td> <td> {this.state.info.records.address} </td>
                      <td> City </td> <td> {this.state.info.records.city} </td>
                    </tr>
                    <tr>
                      <td> State </td> <td> {this.state.info.records.state} </td>
                      <td> Zip </td> <td> {this.state.info.records.zip} </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          )
        }

        {
          this.state.page1Class == "slide-in" && (
            <div className={`page3 ${this.state.page1Class} custom-scroll contract-details`}>
              <div>
                <div className="modal-head top-heading">
                  <h2> Quick Dialer </h2>
                  <button
                    onClick={() => {
                      this.setState({
                        page1Class: 'slide-out2'
                      })
                    }}
                    className="btn btn-link text-white"
                  >
                    <i className="fas fa-times"> </i>
                  </button>
                </div>
                <AvForm
                  onValidSubmit={this.handleSubmitQuickDialer}
                  // model={this.state.record}
                  ref={(c) => (this.form = c)}
                  className="needs-validation"
                >
                  <ModalBody className="row">
                    <div className="col-md-12 mb-3">
                      <AvField
                        name="title"
                        defaultValue={'Quick Dialer'}
                        label="Title"
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <AvField
                        name="from_name"
                        value={uInfo().from_name}
                        label="From Name"
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <AvField
                        name="from_email"
                        value={uInfo().from_email}
                        label="From Email"
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <AvField
                        className="select form-control"
                        type="select"
                        name="phone_number"
                        required
                        label=" Phone Number"
                        value={this.state.record.phone_number}
                      >
                        <option value=""> Select Phone Number </option>
                        {
                          this.state.phoneNumbers.map((row, i) => {
                            return (
                              <option key={i} value={row.phone_number} > {convertPhoneFormat(row.phone_number)}</option>
                            );
                          })
                        }
                      </AvField>
                    </div>
                  </ModalBody>
                  <ModalFooter>

                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => this.setState({ page1Class: 'slide-out2' })}
                    >
                      Cancel
                    </Button>

                    <button
                      className="btn btn-primary w-md waves-effect waves-light"
                      type="submit"
                    >
                      Submit
                    </button>
                  </ModalFooter>
                </AvForm>
              </div>
            </div>
          )
        }
        <ToastContainer />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="m-2 border-20">
                <div className="table-upper d-flex justify-content-between">
                  <CardTitle className="h2 mb-2 mt-1">All Contacts
                    <Dropdown drop="up" className="d-inline ml-5 d-none">
                      <Dropdown.Toggle variant="primary"
                        className="AllLead btn btn-danger btn-sm">
                        Select Current Page
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {
                          $(".lead-checkbox").prop("checked", false);
                          this.setState((prevState) => ({
                            customFieldData: {
                              ...prevState.customFieldData,
                              ['client_ids']: [],
                            },
                          }));
                          let x = this.state.records.map(lead => lead.client_id.toString());

                          this.setState((prevState) => ({
                            customFieldData: {
                              ...prevState.customFieldData,
                              ['client_ids']: [...prevState.customFieldData['client_ids'], ...x],
                            },
                          }));

                          $(".lead-checkbox").prop("checked", true);
                        }}> Select</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                          $(".lead-checkbox").prop("checked", false);
                          this.setState((prevState) => ({
                            customFieldData: {
                              ...prevState.customFieldData,
                              ['client_ids']: [],
                            },
                          }));
                        }}> UnSelect</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </CardTitle>



                </div>
                <hr />
                <ReactDatatable
                  className="table table-border"
                  id="lead-datatable"
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={this.state.dynamicModal}
          fade={true}
          size="md"
          toggle={() => this.closeDynamicModal()}
        >
          <AvForm
            onValidSubmit={this.handleSubmit}
            // model={this.state.record}
            className="needs-validation"
          >
            {this.state.dynamicModalName == "Note" ? (
              <div className="note">
                <div className="modal-head top-heading">
                  <h2> Add Note </h2>
                  <button
                    onClick={() => this.closeDynamicModal()}
                    className="btn btn-link text-white"
                  >
                    <i className="fas fa-times"> </i>
                  </button>
                </div>
                <ModalBody>
                  {this.state.notes.length > 0 ? (
                    this.state.notes.map((row, i) => {
                      return (
                        <div className="CompanyDetail " key={i}>
                          <div key={"note-" + i} className="note activity-info">
                            <div className="activity-body">
                              <div className="activity-bubble note">
                                <div className="activity-content">
                                  <div className="activity_time">
                                    <div className="ActivityTitle" style={{ width: '65%' }}>
                                      <span> {row.type} </span>
                                    </div>
                                    {row.created_at}
                                  </div>
                                  <p> {row.activity} </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-12 col-lg-12 col-xl-12 text-center">
                      No Record Found
                    </div>
                  )}

                  <hr />
                  <div className="mb-3">
                    <AvField
                      className="form-control"
                      type="textarea"
                      name="activity"
                      label=""
                      placeholder="Add a note"
                      required
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="info" type="submit">
                    Add Note
                  </Button>
                  <Button color="danger" onClick={() => this.closeDynamicModal()}>
                    Cancel
                  </Button>
                </ModalFooter>
              </div>
            ) : this.state.dynamicModalName == "sms" ? (
              <div className="sms">
                <div className="modal-head top-heading">
                  <h2> Send SMS </h2>
                  <button
                    onClick={() => this.closeDynamicModal()}
                    className="btn btn-link text-white"
                  >
                    <i className="fas fa-times"> </i>
                  </button>
                </div>
                <ModalBody>
                  <div className="mb-3">
                    <div className="MainTZone SendSMS One">
                      <Select
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        // value={options.filter(({ id }) => id === this.state.country_code)}
                        isMulti={false}
                        options={this.state.smsTemplates}
                        classNamePrefix="select2-selection"
                        name="country_id"
                        placeholder={"Select sms template"}
                        onChange={(e) => {
                          this.setState({
                            smsBody: e.body,
                            template_id: e.id
                          })
                        }}
                      />
                    </div>
                    <AvField type="hidden" name="template_id" value={this.state.template_id} />
                    <AvField type="hidden" name="tos" value={this.state.selectedClient.mobile} />
                    <AvField type="hidden" name="activity" value={"sms send"} />
                  </div>
                  <div className="mb-3">
                    <AvField
                      className="select form-control"
                      type="select"
                      name="froms"
                      required
                    >
                      <option value=""> Select Phone Number </option>
                      {this.state.phoneNumbers.map((row, i) => {
                        return (
                          <option key={"phone-" + i} value={row.phone_number}>
                            {convertPhoneFormat(row.phone_number)}
                          </option>
                        );
                      })}
                    </AvField>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-8">
                      <div className="MainTZone">
                        <Select
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.key}
                          // value={options.filter(({ id }) => id === this.state.country_code)}
                          isMulti={false}
                          options={this.state.tokens}
                          classNamePrefix="select2-selection"
                          name="country_id"
                          placeholder={"Select token"}
                          onChange={(e) => {
                            this.setState({
                              token: `{${e.key}}`
                            })
                          }}
                        />
                      </div>
                      {/* <AvField type="hidden" name="country_code" value={token} /> */}
                    </div>
                    <div className="col-md-4">
                      <button
                        onClick={() => this.setState({ smsBody: `${this.state.smsBody} ${this.state.token}` })}
                        type="button"
                        className="btn btn-primary"
                      >
                        Insert Token
                      </button>
                    </div>
                  </div>
                  <div className="mb-3">
                    <AvField
                      className="form-control"
                      type="textarea"
                      name="body"
                      required
                      value={this.state.smsBody}
                      placeholder={"Enter body"}
                      onChange={(e) => this.setState({ smsBody: e.target.value })}
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="info" type="submit">
                    Send
                  </Button>
                  <Button color="danger" onClick={() => this.closeDynamicModal()}>
                    Cancel
                  </Button>
                </ModalFooter>
              </div>
            ) : this.state.dynamicModalName == "call" ? (
              <div className="call">
                <div className="modal-head top-heading">
                  <h2> Initiate Outbound Call </h2>
                  <button
                    onClick={() => this.closeDynamicModal()}
                    className="btn btn-link text-white"
                    type="button"
                  >
                    <i className="fas fa-times"> </i>
                  </button>
                </div>
                <ModalBody>
                  <div className="mb-3">
                    {this.state.callToken ? (
                      <Phone paramsters={this.state.paramsters} getCallSid={this.getCallSid}> </Phone>
                    ) : (
                      <p> Loading... </p>
                    )}
                  </div>
                </ModalBody>
              </div>
            ) : (
              <div className=""> </div>
            )}
          </AvForm>
        </Modal>

        <Modal
          isOpen={this.state.emailModal}
          fade={true}
          size="lg"
          toggle={() => this.closeDynamicModal()}
        >
          <AvForm
            onValidSubmit={this.handleEmailSubmit}
            // model={this.state.record}
            className="needs-validation"
          >
            <div className="email">
              <div className="modal-head top-heading">
                <h2> Send Email </h2>
                <button
                  onClick={() => this.closeDynamicModal()}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="mb-3">
                  <Select
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                    // value={options.filter(({ id }) => id === this.state.country_code)}
                    isMulti={false}
                    options={this.state.smsTemplates}
                    classNamePrefix="select2-selection"
                    placeholder={"Select email template"}
                    name="country_id"
                    onChange={(e) => {
                      this.setState({
                        subject: e.title
                      })
                      this.getEmailTemplateHtml(e.id);
                    }}
                  />
                  <AvField type="hidden" name="tos" value={this.state.selectedClient.email} />
                  <AvField type="hidden" name="activity" value={"Email: sent"} />
                </div>
                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="text"
                    name="froms"
                    value={uInfo().email}
                    required
                    placeholder="Enter from email"
                  />
                </div>
                <div className="row mb-3">
                  <div className="col-md-12 mb-3">
                    <Select
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      // value={options.filter(({ id }) => id === this.state.country_code)}
                      isMulti={false}
                      options={this.state.tokens}
                      classNamePrefix="select2-selection"
                      name="country_id"
                      onChange={(e) => {
                        this.setState({
                          token: `{${e.key}}`
                        })
                      }}
                    />
                    {/* <AvField type="hidden" name="country_code" value={token} /> */}
                  </div>
                  {this.state.html == "" && (
                    <div className="col-md-4">
                      <button
                        onClick={() => this.setState({ smsBody: `${this.state.smsBody} ${this.state.token}` })}
                        type="button"
                        className="btn btn-primary"
                      >
                        Insert Body
                      </button>
                    </div>
                  )}
                  <div className="col-md-4">
                    <button
                      onClick={() => this.setState({ subject: `${this.state.subject} ${this.state.token}` })}
                      type="button"
                      className="btn btn-primary"
                    >
                      Insert Subject
                    </button>
                  </div>
                </div>
                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                    value={this.state.subject}
                    onChange={(e) => this.setState({ subject: e.target.value })}
                  />
                </div>
                {this.state.html == "" ? (
                  <div className="mb-3">
                    <AvField
                      className="form-control"
                      type="textarea"
                      name="body"
                      placeholder="Enter body"
                      required
                      value={this.state.smsBody}
                      onChange={(e) => this.setState({ smsBody: e.target.value })}
                    />
                  </div>
                ) : (
                  <>
                    <div
                      id="custom-template"
                      className="height-500 custom-scroll"
                      dangerouslySetInnerHTML={{ __html: this.state.html }}
                    ></div>
                    {/* <AvField type="hidden" name="body" value={document.getElementById('custom-template')}/> */}
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="info" type="submit">
                  Send
                </Button>
                <Button color="danger" onClick={() => this.closeDynamicModal()}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          </AvForm>
        </Modal>
        {/* ////////////////// ClientSubcribeModal /////////////////////  */}
        <Modal
          isOpen={this.state.ClientSubcribeModal}
          role="dialog"
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            this.setState({
              ClientSubcribeModal: !this.state.ClientSubcribeModal,
            });
          }}
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                this.setState({
                  ClientSubcribeModal: !this.state.ClientSubcribeModal,
                });
              }}
            >
              Subscribe / Unsubscribe Lead
            </ModalHeader>
            <AvForm
              onValidSubmit={this.ClientSubcribeSubmit}
              className="needs-validation"
            >
              <ModalBody>
                <div className="mb-3">
                  <AvField
                    className="select form-control"
                    type="select"
                    name="call_status"
                    label="Landline & Mobile"
                    required
                    value={'1'}
                  >
                    <option value={'1'}> Subscribe </option>
                    <option value={'0'}> Unsubscribe </option>
                  </AvField>
                </div>
                <div className="mb-3">
                  <AvField
                    className="select form-control"
                    type="select"
                    name="email_status"
                    label="Email"
                    required
                    value={'1'}
                  >
                    <option value={'1'}> Subscribe </option>
                    <option value={'0'}> Unsubscribe </option>
                  </AvField>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    this.setState({
                      ClientSubcribeModal: !this.state.ClientSubcribeModal,
                    });
                  }}
                >
                  Close
                </Button>
                <button
                  className="btn btn-primary waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
              </ModalFooter>
            </AvForm>
          </div>
        </Modal>
        {/* ////////////////// ClientSubcribeModal //////////////////// */}
      </React.Fragment >
    );
  }
}
